import {version} from './config';

export const homeRoutes = {
	name: 'Home',
	to: '/',
	exact: true,
	icon: 'chart-pie',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/',
			name: 'Dashboard',
			exact: true,
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/dashboard-alt',
			name: 'Dashboard alt',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/feed',
			name: 'Feed',
			exact: true,
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/landing',
			name: 'Landing',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const authenticationRoutes = {
	name: 'Authentication',
	to: '/authentication',
	icon: 'lock',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/authentication/basic',
			name: 'Basic',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/authentication/basic/login',
					name: 'Login',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/logout',
					name: 'Logout',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/register',
					name: 'Register',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/forget-password',
					name: 'Forgot password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/password-reset',
					name: 'Reset password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/confirm-mail',
					name: 'Confirm mail',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/basic/lock-screen',
					name: 'Lock screen',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/authentication/card',
			name: 'Card',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/authentication/card/login',
					name: 'Login',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/logout',
					name: 'Logout',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/register',
					name: 'Register',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/forget-password',
					name: 'Forgot password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/password-reset',
					name: 'Reset password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/confirm-mail',
					name: 'Confirm mail',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/card/lock-screen',
					name: 'Lock screen',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/authentication/split',
			name: 'Split',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/authentication/split/login',
					name: 'Login',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/logout',
					name: 'Logout',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/register',
					name: 'Register',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/forget-password',
					name: 'Forgot password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/password-reset',
					name: 'Reset password',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/confirm-mail',
					name: 'Confirm mail',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/authentication/split/lock-screen',
					name: 'Lock screen',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/authentication/wizard',
			name: 'Wizard',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const ECommerceRoutes = {
	name: 'E commerce',
	to: '/e-commerce',
	icon: 'cart-plus',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/e-commerce/products/list',
			name: 'Product list',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/products/grid',
			name: 'Product grid',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/product-details',
			name: 'Product details',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/orders',
			name: 'Orders',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/order-details',
			name: 'Order details',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/customers',
			name: 'Customers',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/shopping-cart',
			name: 'Shopping cart',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/checkout',
			name: 'Checkout',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/e-commerce/favourite-items',
			name: 'Favourite items',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const pageRoutes = {
	name: 'Pages',
	to: '/pages',
	icon: 'copy',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/pages/activity',
			name: 'Activity',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/associations',
			name: 'Associations',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/billing',
			name: 'Billing',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/customer-details',
			name: 'Customer details',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/event-detail',
			name: 'Event detail',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/event-create',
			name: 'Event create',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/events',
			name: 'Events',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/faq',
			name: 'Faq',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/invoice',
			name: 'Invoice',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/invite-people',
			name: 'Invite people',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/notifications',
			name: 'Notifications',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/people',
			name: 'People',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/pricing',
			name: 'Pricing',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/pricing-alt',
			name: 'Pricing alt',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/profile',
			name: 'Profile',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/settings',
			name: 'Settings',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/pages/starter',
			name: 'Starter',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/errors',
			name: 'Errors',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/errors/404',
					name: '404',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/errors/500',
					name: '500',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
	],
};
export const widgetsRoutes = {
	name: 'Widgets',
	to: '/widgets',
	exact: true,
	icon: 'poll',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
};

export const chatRoutes = {
	name: 'Chat',
	to: '/chat',
	exact: true,
	icon: 'comments',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
};

export const kanbanRoutes = {
	name: 'Kanban',
	to: '/kanban',
	exact: true,
	icon: ['fab', 'trello'],
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
};

export const emailRoutes = {
	name: 'Email',
	to: '/email',
	icon: 'envelope-open',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/email/inbox',
			name: 'Inbox',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/email/email-detail',
			name: 'Email detail',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/email/compose',
			name: 'Compose',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const documentationRoutes = {
	name: 'Documentation',
	to: '/documentation',
	exact: true,
	icon: 'book',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
};

export const changelogRoutes = {
	name: 'ChangeLog',
	to: '/changelog',
	exact: true,
	icon: 'code-branch',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	badge: {
		text: `v${version}`,
		color: 'soft-primary',
	},
};

export const componentRoutes = {
	name: 'Components',
	to: '/components',
	icon: 'puzzle-piece',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/components/alerts',
			name: 'Alerts',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/accordions',
			name: 'Accordions',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/avatar',
			name: 'Avatar',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/badges',
			name: 'Badges',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/backgrounds',
			name: 'Backgrounds',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/breadcrumb',
			name: 'Breadcrumb',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/buttons',
			name: 'Buttons',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/cards',
			name: 'Cards',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/cookie-notice',
			name: 'Cookie notice',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/collapses',
			name: 'Collapses',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/carousel',
			name: 'Carousel',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/dropdowns',
			name: 'Dropdowns',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/forms',
			name: 'Forms',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/listgroups',
			name: 'List groups',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/modals',
			name: 'Modals',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/navs',
			name: 'Navs',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},

		{
			to: '/components',
			name: 'Navbar',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/components/navbars',
					name: 'Default',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/components/navbar-vertical',
					name: 'Vertical',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
					badge: {
						text: 'New',
					},
				},
				{
					to: '/components/navbar-top',
					name: 'Top',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/components/pageheaders',
			name: 'Page headers',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/paginations',
			name: 'Paginations',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/popovers',
			name: 'Popovers',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/progress',
			name: 'Progress',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/sidepanel',
			name: 'Sidepanel',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/spinners',
			name: 'Spinners',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},

		{
			to: '/components/tables',
			name: 'Tables',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/components/tooltips',
			name: 'Tooltips',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const pluginRoutes = {
	name: 'Plugins',
	to: '/plugins',
	icon: 'plug',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/plugins/bulk-select',
			name: 'Bulk select',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/react-beautiful-dnd',
			name: 'Beautiful DnD',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins',
			name: 'Chart',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/plugins/chart',
					name: 'Chart Js',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/plugins/echarts',
					name: 'Echarts',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/plugins/countup',
			name: 'Countup',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/code-highlight',
			name: 'Code Highlight',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/datetime',
			name: 'Datetime',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/dropzone',
			name: 'Dropzone',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/emoji-mart',
			name: 'Emoji Mart',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/fontawesome',
			name: 'Fontawesome',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/image-lightbox',
			name: 'Image lightbox',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/lottie',
			name: 'Lottie',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins',
			name: 'Map',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
			children: [
				{
					to: '/plugins/leaflet-map',
					name: 'Leaflet map',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/plugins/google-map',
					name: 'Google map',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
				{
					to: '/plugins/echart-map',
					name: 'Echart Map',
					allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
				},
			],
		},
		{
			to: '/plugins/plyr',
			name: 'Plyr',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/progressbar',
			name: 'Progressbar',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/react-hook-form',
			name: 'React Hook Form',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/react-bootstrap-table2',
			name: 'BS Table2',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/select',
			name: 'Select',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/slick-carousel',
			name: 'Slick Carousel',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/scroll-bar',
			name: 'Scroll Bar',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/toastify',
			name: 'Toastify',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/typed',
			name: 'Typed',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/plugins/wysiwyg',
			name: 'WYSIWYG editor',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export const utilityRoutes = {
	name: 'Utilities',
	to: '/utilities',
	icon: ['fab', 'hotjar'],
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/utilities/borders',
			name: 'Borders',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/clearfix',
			name: 'Clearfix',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/closeIcon',
			name: 'Close icon',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/colors',
			name: 'Colors',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/display',
			name: 'Display',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/embed',
			name: 'Embed',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/figures',
			name: 'Figures',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/flex',
			name: 'Flex',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/grid',
			name: 'Grid',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/sizing',
			name: 'Sizing',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/spacing',
			name: 'Spacing',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/stretchedLink',
			name: 'Stretched link',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/typography',
			name: 'Typography',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/verticalAlign',
			name: 'Vertical align',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/utilities/visibility',
			name: 'Visibility',
			allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
		},
	],
};

export default [
	homeRoutes,
	pageRoutes,
	chatRoutes,
	kanbanRoutes,
	emailRoutes,
	authenticationRoutes,
	ECommerceRoutes,
	widgetsRoutes,
	componentRoutes,
	utilityRoutes,
	pluginRoutes,
	documentationRoutes,
	changelogRoutes,
];
