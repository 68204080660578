import axios from 'axios';
import {baseUrl} from '../config';
require('dotenv').config();

export const getTags = (token) => {
	const res = axios.get(baseUrl + '/tag', {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const deleteTagByID = (token, tagID) => {
	const res = axios.delete(baseUrl + '/tag/' + tagID, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const deleteTags = (token, tagList) => {
	const res = axios.delete(baseUrl + '/tag', {
		data: tagList,
		headers: {'x-auth-token': token},
	});
	return res;
};

export const updateTag = (token, tagID, tagType) => {
	const res = axios.patch(
		baseUrl + '/tag/' + tagID,
		{type: tagType},
		{
			headers: {'x-auth-token': token},
		}
	);
	return res;
};

export const createTag = (token, tag) => {
	const res = axios.post(baseUrl + '/tag', tag, {
		headers: {'x-auth-token': token},
	});
	return res;
};

// //TAGTYPES

export const getTagTypes = (token) => {
	const res = axios.get(baseUrl + '/tag/type', {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const createTagType = (token, payload) => {
	const res = axios.post(baseUrl + '/tag/type', payload, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const updateTagType = (token, tagType, payload) => {
	const res = axios.put(baseUrl + '/tag/type/' + tagType, payload, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const deleteTagType = (token, tagType) => {
	const res = axios.delete(baseUrl + '/tag/type/' + tagType, {
		headers: {'x-auth-token': token},
	});
	return res;
};
