import axios from 'axios';
import {baseUrl} from '../config';
require('dotenv').config();

export const getUsers = (token) => {
	const res = axios.get(baseUrl + '/admin/allUsers', {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const deleteUser = (token, userID) => {
	const res = axios.delete(baseUrl + '/admin', {
		data: userID,
		headers: {'x-auth-token': token},
	});
	return res;
};

export const getUserByID = (token, user) => {
	const res = axios.get(baseUrl + '/admin/user/' + user, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const updateUser = (token, user) => {
	const res = axios.put(baseUrl + '/admin/user/' + user.userID, user, {
		headers: {'x-auth-token': token},
	});
	return res;
};
