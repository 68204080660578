export const version = '0.1.0';
export const navbarBreakPoint = 'xl'; // Vertical navbar breakpoint
export const topNavbarBreakpoint = 'lg';
export const baseUrl = (function() {
	switch (process.env.REACT_APP_ENVIRONMENT) {
		case 'test':
			return 'https://testapi.boxter.dk/api';
		case 'production':
			return 'https://api.boxter.dk/api';
		default:
			return 'http://localhost:5000/api';
	}
})();
