export const productColors = ['#2c7be5', '#d8e2ef'];

export const totalSalesByMonth = {
	january: [60, 80, 60, 80, 65, 130, 120, 100, 30, 40, 30, 70, 80],
	february: [100, 70, 80, 50, 120, 100, 130, 140, 90, 100, 40, 50, 60],
	march: [80, 50, 60, 40, 60, 120, 100, 130, 60, 80, 50, 60, 70],
	april: [70, 80, 100, 70, 90, 60, 80, 130, 40, 60, 50, 80, 90],
	may: [90, 40, 80, 80, 100, 140, 100, 130, 90, 60, 70, 50, 60],
	june: [80, 60, 80, 60, 40, 100, 120, 100, 30, 40, 30, 70, 80],
	july: [20, 40, 20, 50, 70, 60, 110, 80, 90, 30, 50, 50, 60],
	augist: [60, 70, 30, 40, 80, 140, 80, 140, 120, 130, 100, 110, 120],
	september: [90, 90, 40, 60, 40, 110, 90, 110, 60, 80, 60, 70, 80],
	october: [50, 80, 50, 80, 50, 80, 120, 80, 50, 120, 110, 110, 120],
	november: [60, 90, 60, 70, 40, 70, 100, 140, 30, 40, 30, 70, 180],
	december: [20, 40, 20, 50, 30, 80, 120, 100, 30, 40, 30, 70, 80],
};

export default [
	['product', '2019', '2018'],
	['Boots4', 43, 85],
	['Reign Pro', 83, 73],
	['Slick', 86, 62],
	['Falcon', 72, 53],
	['Sparrow', 80, 50],
	['Hideaway', 50, 70],
	['Freya', 80, 90],
];
