import team1 from '../../assets/img/team/1.jpg';
import team2 from '../../assets/img/team/2.jpg';
import team3 from '../../assets/img/team/3.jpg';
import team4 from '../../assets/img/team/4.jpg';
import team5 from '../../assets/img/team/5.jpg';
import team6 from '../../assets/img/team/6.jpg';

export default [
	{
		id: 1,
		name: 'Emma Watson',
		avatar: {
			src: team1,
			size: '2xl',
			status: 'online',
		},
		role: 'Admin',
	},
	{
		id: 2,
		name: 'Antony Hopkins',
		avatar: {
			src: team2,
			size: '2xl',
			status: 'online',
		},
		role: 'Moderator',
	},
	{
		id: 3,
		name: 'Anna Karinina',
		avatar: {
			src: team3,
			size: '2xl',
			status: 'away',
		},
		role: 'Editor',
	},
	{
		id: 4,
		name: 'John Lee',
		avatar: {
			src: team4,
			size: '2xl',
			status: 'offline',
		},
		role: 'Admin',
	},
	{
		id: 5,
		name: 'Rowen Atkinson',
		avatar: {
			src: team5,
			size: '2xl',
			status: 'offline',
		},
		role: 'Editor',
	},
	{
		id: 6,
		name: 'Isaac Hempstead',
		avatar: {
			src: team6,
			size: '2xl',
			status: 'offline',
		},
		role: 'Moderator',
	},
];
