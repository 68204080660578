import axios from 'axios';
import {baseUrl} from '../config';
require('dotenv').config();

export const getEvents = (token) => {
	const res = axios.get(baseUrl + '/event', {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const createEvent = (token, event) => {
	const res = axios.post(baseUrl + '/event', event, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const getEvent = (token, id) => {
	const res = axios.get(baseUrl + '/event/' + id, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const deleteEvent = (token, id) => {
	const res = axios.delete(baseUrl + '/event/' + id, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const updateEvent = (token, event) => {
	const res = axios.put(baseUrl + '/event/' + event.id, event, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const addMembers = (token, id, members) => {
	const res = axios.post(baseUrl + '/event/' + id + '/members', members, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const editMemberRole = (token, eventId, members) => {
	const res = axios.patch(baseUrl + '/event/' + eventId + '/members', members, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const removeMember = (token, eventId, members) => {
	const res = axios.delete(baseUrl + '/event/' + eventId + '/members', {
		headers: {'x-auth-token': token},
		data: members,
	});
	return res;
};
