import product5 from '../../assets/img/products/5.jpg';
import product3 from '../../assets/img/products/3.jpg';
import iconZip from '../../assets/img/icons/zip.png';
import product2 from '../../assets/img/products/2.jpg';
import iconDocs from '../../assets/img/icons/docs.png';

export default [
	{
		id: 1,
		img: product5,
		name: 'apple-smart-watch.png',
		user: 'Antony',
		time: 'Just Now',
	},
	{
		id: 2,
		img: product3,
		name: 'iphone.jpg',
		user: 'Antony',
		time: 'Yesterday at 1:30 PM',
	},
	{
		id: 3,
		img: iconZip,
		name: 'Falcon v1.8.2',
		user: 'Jane',
		time: '27 Sep at 10:30 AM',
		border: false,
	},
	{
		id: 4,
		img: product2,
		name: 'iMac.jpg',
		user: 'Rowen',
		time: '23 Sep at 6:10 PM',
	},
	{
		id: 5,
		img: iconDocs,
		name: 'functions.php',
		user: 'John',
		time: '1 Oct at 4:30 PM',
		border: false,
	},
];
