import React, {useContext, useEffect} from 'react';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from 'reactstrap';
import {authService} from '../../endpoints';
import {OrganisationContext} from '../../context/Context';

const OrganizersDropdown = () => {
	const {orgData, setOrgData} = useContext(OrganisationContext);
	const organisations = authService.getOrganizers();

	useEffect(() => {
		setOrgData(organisations[0]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const dropdownItems = organisations.map((organisation) => {
		return (
			<DropdownItem
				key={organisation.orgId}
				onClick={() => setOrgData(organisation)}
			>
				{organisation.orgName}
			</DropdownItem>
		);
	});

	return (
		<UncontrolledDropdown nav inNavbar>
			<DropdownToggle nav caret>
				{orgData.orgName}
			</DropdownToggle>
			<DropdownMenu right>{dropdownItems}</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default OrganizersDropdown;
