// Desc: Routes for the boxter app

// Route for the events page
export const events = {
	name: 'Events',
	to: '/events',
	icon: 'calendar-day',
	allowedroles: ['USER', 'ADMIN', 'SUPERADMIN'],
};

// Route for the boxter managment page
export const boxterManagmentRoutes = {
	name: 'Boxter Managment',
	to: '/component',
	icon: 'boxes',
	allowedroles: ['ADMIN', 'SUPERADMIN'],
	children: [
		{
			to: '/boxterManagement/users',
			name: 'Brugere',
			allowedroles: ['ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/boxterManagement/box',
			name: 'Bokse',
			allowedroles: ['ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/boxterManagement/tags',
			name: 'Tags',
			allowedroles: ['ADMIN', 'SUPERADMIN'],
		},
		{
			to: '/boxterManagement/types',
			name: 'Typer',
			allowedroles: ['ADMIN', 'SUPERADMIN'],
		},
	],
};

export default [events, boxterManagmentRoutes];
