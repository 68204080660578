import React from 'react';
import {Card, CardBody} from 'reactstrap';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Error500 = () => (
	<Card className='text-center h-100'>
		<CardBody className='p-5'>
			<div className='display-1 text-200 fs-error'>500</div>
			<p className='lead mt-4 text-800 text-sans-serif font-weight-semi-bold'>
				Hovsa, noget gik galt!
			</p>
			<hr />
			<p>
				Prøv at opdatere siden, eller gå tilbage og prøv handlingen igen. Hvis
				dette problem fortsætter
				<a href='mailto:kontakt@boxter.dk' className='ml-1'>
					kontakt os
				</a>
				.
			</p>
			<Link className='btn btn-primary btn-sm mt-3' to='/'>
				<FontAwesomeIcon icon='home' className='mr-2' />
				Forside
			</Link>
		</CardBody>
	</Card>
);

export default Error500;
