import React from 'react';
import {Nav, NavItem} from 'reactstrap';
import ProfileDropdown from './ProfileDropdown';
import OrganizersDropdown from './OrganizersDropdown';

const TopNavRightSideNavItem = () => {
	return (
		<Nav
			navbar
			className='navbar-nav-icons ml-auto flex-row align-items-center'
		>
			<NavItem>
				<OrganizersDropdown />
			</NavItem>
			<ProfileDropdown />
		</Nav>
	);
};

export default TopNavRightSideNavItem;
