import axios from 'axios';
import {baseUrl} from '../config';

export const getOrganisations = (token) => {
	const res = axios.get(baseUrl + '/organisation', {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const inviteUser = (token, user) => {
	const res = axios.post(baseUrl + '/organisation/inviteUser', user, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const getOrganisationByID = (token, organisation) => {
	const res = axios.get(baseUrl + '/organisation/' + organisation, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const getEventsByOrganisation = (token, orgId) => {
	const res = axios.get(baseUrl + '/organisation/' + orgId + '/events', {
		headers: {'x-auth-token': token},
	});
	return res;
};
