const authService = require('./auth');
const adminService = require('./admin');
const tagService = require('./tags');
const boxService = require('./box');
const roleService = require('./role');
const organisationService = require('./organisation');
const eventService = require('./event');
const activityService = require('./activity');

// Get all utils and export them to require them with greater ease.
module.exports = {
	adminService,
	authService,
	tagService,
	boxService,
	roleService,
	organisationService,
	eventService,
	activityService,
};
