import axios from 'axios';
import {baseUrl} from '../config';

require('dotenv').config();
const token = localStorage.getItem('BoxterToken');

export const getEventById = (activityId) => {
	const res = axios.get(baseUrl + `/activity/${activityId}`, {
		headers: {'x-auth-token': token},
	});
	return res;
};

export const getCheckIns = (activityId) => {
	const res = axios.get(baseUrl + `/activity/${activityId}/checkins/`, {
		headers: {'x-auth-token': token},
	});
	return res;
};
