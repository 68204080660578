import axios from 'axios';
import {baseUrl} from '../config';
require('dotenv').config();

export const getRoles = (token) => {
	const res = axios.get(baseUrl + '/role', {
		headers: {'x-auth-token': token},
	});
	return res;
};
