import moment from 'moment';

export const isIterableArray = (array) =>
	Array.isArray(array) && !!array.length;

//===============================
// Breakpoints
//===============================
export const breakpoints = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
	xxl: 1540,
};

//===============================
// Store
//===============================
export const getItemFromStore = (key, defaultValue, store = localStorage) =>
	JSON.parse(store.getItem(key)) || defaultValue;
export const setItemToStore = (key, payload, store = localStorage) =>
	store.setItem(key, JSON.stringify(payload));
export const getStoreSpace = (store = localStorage) =>
	parseFloat(
		(
			escape(encodeURIComponent(JSON.stringify(store))).length /
			(1024 * 1024)
		).toFixed(2)
	);

//===============================
// Cookie
//===============================
export const getCookieValue = (name) => {
	const value = document.cookie.match(
		'(^|[^;]+)\\s*' + name + '\\s*=\\s*([^;]+)'
	);
	return value ? value.pop() : null;
};

export const createCookie = (name, value, cookieExpireTime) => {
	const date = new Date();
	date.setTime(date.getTime() + cookieExpireTime);
	const expires = '; expires=' + date.toUTCString();
	document.cookie = name + '=' + value + expires + '; path=/';
};

//===============================
// Moment
//===============================
export const getDuration = (startDate, endDate) => {
	if (!moment.isMoment(startDate))
		throw new Error(
			`Start date must be a moment object, received ${typeof startDate}`
		);
	if (endDate && !moment.isMoment(endDate))
		throw new Error(
			`End date must be a moment object, received ${typeof startDate}`
		);

	return `${startDate.format('ll')} - ${
		endDate ? endDate.format('ll') : 'Present'
	} • ${startDate.from(endDate || moment(), true)}`;
};

export const numberFormatter = (number, fixed = 2) => {
	// Nine Zeroes for Billions
	return Math.abs(Number(number)) >= 1.0e9
		? (Math.abs(Number(number)) / 1.0e9).toFixed(fixed) + 'B'
		: // Six Zeroes for Millions
		Math.abs(Number(number)) >= 1.0e6
		? (Math.abs(Number(number)) / 1.0e6).toFixed(fixed) + 'M'
		: // Three Zeroes for Thousands
		Math.abs(Number(number)) >= 1.0e3
		? (Math.abs(Number(number)) / 1.0e3).toFixed(fixed) + 'K'
		: Math.abs(Number(number)).toFixed(fixed);
};

//===============================
// Colors
//===============================
export const hexToRgb = (hexValue) => {
	let hex;
	hexValue.indexOf('#') === 0
		? (hex = hexValue.substring(1))
		: (hex = hexValue);
	// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
	const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
	const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(
		hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)
	);
	return result
		? [
				parseInt(result[1], 16),
				parseInt(result[2], 16),
				parseInt(result[3], 16),
		  ]
		: null;
};

export const rgbColor = (color = colors[0]) => `rgb(${hexToRgb(color)})`;
export const rgbaColor = (color = colors[0], alpha = 0.5) =>
	`rgba(${hexToRgb(color)},${alpha})`;

export const colors = [
	'#2c7be5',
	'#00d97e',
	'#e63757',
	'#39afd1',
	'#fd7e14',
	'#02a8b5',
	'#727cf5',
	'#6b5eae',
	'#ff679b',
	'#f6c343',
];

export const themeColors = {
	primary: '#2c7be5',
	secondary: '#748194',
	success: '#00d27a',
	info: '#27bcfd',
	warning: '#f5803e',
	danger: '#e63757',
	light: '#f9fafd',
	dark: '#0b1727',
};

export const grays = {
	white: '#fff',
	100: '#f9fafd',
	200: '#edf2f9',
	300: '#d8e2ef',
	400: '#b6c1d2',
	500: '#9da9bb',
	600: '#748194',
	700: '#5e6e82',
	800: '#4d5969',
	900: '#344050',
	1000: '#232e3c',
	1100: '#0b1727',
	black: '#000',
};

export const darkGrays = {
	white: '#fff',
	1100: '#f9fafd',
	1000: '#edf2f9',
	900: '#d8e2ef',
	800: '#b6c1d2',
	700: '#9da9bb',
	600: '#748194',
	500: '#5e6e82',
	400: '#4d5969',
	300: '#344050',
	200: '#232e3c',
	100: '#0b1727',
	black: '#000',
};

export const getGrays = (isDark) => (isDark ? darkGrays : grays);

export const rgbColors = colors.map((color) => rgbColor(color));
export const rgbaColors = colors.map((color) => rgbaColor(color));

//===============================
// Echarts
//===============================
export const getPosition = (pos, params, dom, rect, size) => ({
	top: pos[1] - size.contentSize[1] - 10,
	left: pos[0] - size.contentSize[0] / 2,
});

//===============================
// E-Commerce
//===============================
export const calculateSale = (base, less = 0, fix = 2) =>
	(base - base * (less / 100)).toFixed(fix);
export const getTotalPrice = (cart, baseItems) =>
	cart.reduce((accumulator, currentValue) => {
		const {id, quantity} = currentValue;
		const {price, sale} = baseItems.find((item) => item.id === id);
		return accumulator + calculateSale(price, sale) * quantity;
	}, 0);

//===============================
// Helpers
//===============================
export const getPaginationArray = (totalSize, sizePerPage) => {
	const noOfPages = Math.ceil(totalSize / sizePerPage);
	const array = [];
	let pageNo = 1;
	while (pageNo <= noOfPages) {
		array.push(pageNo);
		pageNo = pageNo + 1;
	}
	return array;
};

export const capitalize = (str) =>
	(str.charAt(0).toUpperCase() + str.slice(1)).replace(/-/g, ' ');

export const routesSlicer = ({routes, columns = 3, rows}) => {
	const routesCollection = [];
	routes.map((route) => {
		if (route.children) {
			return route.children.map((item) => {
				if (item.children) {
					return routesCollection.push(...item.children);
				}
				return routesCollection.push(item);
			});
		}
		return routesCollection.push(route);
	});

	const totalRoutes = routesCollection.length;
	const calculatedRows = rows || Math.ceil(totalRoutes / columns);
	const routesChunks = [];
	for (let i = 0; i < totalRoutes; i += calculatedRows) {
		routesChunks.push(routesCollection.slice(i, i + calculatedRows));
	}
	return routesChunks;
};

export const getPageName = (pageName) => {
	return window.location.pathname.split('/').slice(-1)[0] === pageName;
};

export const copyToClipBoard = (textFieldRef) => {
	const textField = textFieldRef.current;
	textField.focus();
	textField.select();
	document.execCommand('copy');
};
